
const ChanalVOR = (e) => {
    if (/\d\d\d\.\d$/.test(e.target.value) || /\d\d\d\.\d\d$/.test(e.target.value)) {
        e.target.style = 'background-color: #9EE99E;'
    }else {e.target.style = 'background-color: white;'}
    if ((String(e.target.value).length === 3) && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
        e.target.value += '.'
    }
}

const ChanalRSBN = (e) => {
    if ((String(e.target.value).length === 1 || String(e.target.value).length === 2)) {
        e.target.style = 'background-color: #9EE99E;'
    }else {e.target.style = 'background-color: white;'}
}

const Name = (e) => {
    if (/./.test(e.target.value)) {
        e.target.style = 'background-color: #9EE99E;'
    }else {e.target.style = 'background-color: white;'}
}

const Dprm = (e) => {
    if (/\d\d\d\(\D\D\D\D\D\)$/.test(e.target.value)) {
        e.target.style = 'background-color: #9EE99E;'
    }else {e.target.style = 'background-color: white;'}

    if ((String(e.target.value).length === 3) && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
        e.target.value += '('
    }
    if ((String(e.target.value).length === 6) && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
        e.target.value += '/'
    }
    if ((String(e.target.value).length === 9) && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
        e.target.value += ')'
        e.target.style = 'background-color: #9EE99E;'
    }
}
const Bprm = (e) => {
    if (/\d\d\d\(\D\D\D\)$/.test(e.target.value)) {
        e.target.style = 'background-color: #9EE99E;'
    }else {e.target.style = 'background-color: white;'}

    if ((String(e.target.value).length === 3) && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
        e.target.value += '('
    }
    if ((String(e.target.value).length === 5) && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
        e.target.value += '/'
    }
    if ((String(e.target.value).length === 7) && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
        e.target.value += ')'
        e.target.style = 'background-color: #9EE99E;'
    }
}
const Oprm = (e) => {
    if (/\d\d\d\(\D\D\)$/.test(e.target.value)) {
        e.target.style = 'background-color: #9EE99E;'
    }else {e.target.style = 'background-color: white;'}

    if ((String(e.target.value).length === 3) && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
        e.target.value += '('
    }
    if ((String(e.target.value).length === 6) && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
        e.target.value += ')'
        e.target.style = 'background-color: #9EE99E;'
    }
}
const Hbez = (e) => {
    if ((/\d\d\d\D\(.+\)$/.test(e.target.value)) || (/\d\d\d\d\D\(.+\)$/.test(e.target.value))) {
        e.target.style = 'background-color: #9EE99E;'
    }else {e.target.style = 'background-color: white;'}

    if (String(e.target.value).slice(-1) === 'м' && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
        e.target.value += '(ИКпод='
    }
    if (String(e.target.value).slice(-1) === '.' && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
        e.target.value += '..'
    }
}
const Htr = (e) => {
    if ((/\d\d\d\D\(.+\)$/.test(e.target.value)) || (/\d\d\d\d\D\(.+\)$/.test(e.target.value))) {
        e.target.style = 'background-color: #9EE99E;'
    }else {e.target.style = 'background-color: white;'}

    if (String(e.target.value).slice(-1) === 'м' && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
        e.target.value += '(Раэр'
    }
    if (String(e.target.value).slice(-1) === '.' && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
        e.target.value += '..'
    }
}
const Hkry = (e) => {
    if ((String(e.target.value).length === 3) || (String(e.target.value).length === 4)) {
        e.target.style = 'background-color: #9EE99E;'
    }else {e.target.style = 'background-color: white;'}
}
const CordYa = (e) => {
    if ((/\d\d\.\d+/.test(e.target.value)) || (/\d\d\d\.\d+/.test(e.target.value))) {
        e.target.style = 'background-color: #9EE99E;'
    }else {e.target.style = 'background-color: white;'}

    if (String(e.target.value).length === 2 && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
        e.target.value += '.'
    }
}
const CordPr = (e) => {
    if ((/\d\d \d\d \d\d$/.test(e.target.value)) || (/\d\d\d \d\d \d\d$/.test(e.target.value))) {
        e.target.style = 'background-color: #9EE99E;'
    }else {e.target.style = 'background-color: white;'}
    
    if ((+e.target.value[0] !== 0) && (+e.target.value[0] !== 1)) {
        if (String(e.target.value).length === 2 && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
            e.target.value += ' '
        }
        if (String(e.target.value).length === 5 && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
            e.target.value += ' '
        }
    }
    if ((+e.target.value[0] === 0) || (+e.target.value[0] === 1)) {
        if (String(e.target.value).length === 3 && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
            e.target.value += ' '
        }
        if (String(e.target.value).length === 6 && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
            e.target.value += ' '
        }
    }
}
const CordWgs = (e) => {  //55° 55  ̍ 55.00''
    if ((/\d\d.+\d\d.+\d\d\.\d\d..$/.test(e.target.value)) || (/\d\d\d.+\d\d.+\d\d\.\d\d..$/.test(e.target.value))) {
        e.target.style = 'background-color: #9EE99E;'
    }else {e.target.style = 'background-color: white;'}

    if ((+e.target.value[0] !== 0) && (+e.target.value[0] !== 1)) {
        if (String(e.target.value).length === 2 && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
            e.target.value += '° '
        }
        if (String(e.target.value).length === 6 && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
            e.target.value += '  ̍ '
        }
        if (String(e.target.value).length === 12 && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
            e.target.value += '.'
        }
        if (String(e.target.value).length === 15 && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
            e.target.value += "''"
            e.target.style = 'background-color: #9EE99E;'
        }
    }
    if ((+e.target.value[0] === 0) || (+e.target.value[0] === 1)) {
        if (String(e.target.value).length === 3 && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
            e.target.value += '° '
        }
        if (String(e.target.value).length === 7 && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
            e.target.value += '  ̍ '
        }
        if (String(e.target.value).length === 13 && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
            e.target.value += '.'
        }
        if (String(e.target.value).length === 16 && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
            e.target.value += "''"
            e.target.style = 'background-color: #9EE99E;'
        }
    }
}
const Ipu = (e) => {
    if ((/\d\d\d..\d\d$/.test(e.target.value)) || (/\d\d..\d\d$/.test(e.target.value))) {
        e.target.style = 'background-color: #9EE99E;'
    }else {e.target.style = 'background-color: white;'}

    if (String(e.target.value).length === 3 && !(e.nativeEvent.inputType === 'deleteContentBackward')) {
        e.target.value += '° '
    }
}
const LVpp = (e) => {
    if (String(e.target.value).length === 4) {
        e.target.style = 'background-color: #9EE99E;'
    }else {e.target.style = 'background-color: white;'}
}






export {ChanalVOR, ChanalRSBN, Name, Dprm, Bprm, Oprm, Hbez, Htr, Hkry, CordYa, CordPr, CordWgs, Ipu, LVpp}