import { Component } from "react";

import Body from "../body/body";

import './app.css';

class App extends Component {
    render() {
        return (
        <div>
            <Body/>
        </div>
    )
    }
}

export default App;