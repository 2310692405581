const OneAerodrome = (bd,bdLength) => {
    try {
    if (bd !== 'New') {
      if (bd.notes) {document.getElementById('notes').style = 'background-color: greenyellow'};
      document.getElementById('notesValue').textContent = bd.notes;
      document.getElementById('coordinates__vor__pz__lat').placeholder = '';
      document.getElementById('coordinates__vor__pz__lon').placeholder = '';
      document.getElementById('city').value = 
        bd.city ? bd.city : document.getElementById('city').placeholder = '';
      document.getElementById('name').value = 
        bd.name ? bd.name : document.getElementById('name').placeholder = '';
      document.getElementById('name_aerodroms').value = 
        bd.name_aerodroms ? bd.name_aerodroms : document.getElementById('name_aerodroms').placeholder = '';
      document.getElementById('coordinates__kta__wgs_deg__lat').value = 
        bd.coordinates.kta.wgs_deg.lat ? bd.coordinates.kta.wgs_deg.lat : 
        document.getElementById('coordinates__kta__wgs_deg__lat').placeholder = '';
      document.getElementById('coordinates__kta__wgs_deg__lon').value = 
        bd.coordinates.kta.wgs_deg.lon ? bd.coordinates.kta.wgs_deg.lon : 
        document.getElementById('coordinates__kta__wgs_deg__lon').placeholder = '';
      document.getElementById('callsign').value =
        bd.callsign ? bd.callsign : document.getElementById('callsign').placeholder = '';
      document.getElementById('rsbn__channel_nav').value = 
        bd.rsbn.channel_nav ? bd.rsbn.channel_nav : document.getElementById('rsbn__channel_nav').placeholder = '';
      document.getElementById('rsbn__channel_land').value = 
        bd.rsbn.channel_land ? bd.rsbn.channel_land : document.getElementById('rsbn__channel_land').placeholder = '';
      document.getElementById('channel_vor').value = 
        bd.channel_vor ? bd.channel_vor : document.getElementById('channel_vor').placeholder = '';
      document.getElementById('near_beacon').value = 
        bd.near_beacon ? bd.near_beacon : document.getElementById('near_beacon').placeholder = '';
      document.getElementById('far_beacon').value = 
        bd.far_beacon ? bd.far_beacon : document.getElementById('far_beacon').placeholder = '';
      document.getElementById('general_beacon').value = 
        bd.general_beacon ? bd.general_beacon : document.getElementById('general_beacon').placeholder = '';
      document.getElementById('channel_ils__0').value = 
        bd.channel_ils[0] ? bd.channel_ils[0] : document.getElementById('channel_ils__0').placeholder = '';
      document.getElementById('channel_ils__1').value = 
        bd.channel_ils[1] ? bd.channel_ils[1] : document.getElementById('channel_ils__1').placeholder = '';
      
      if (!(bd.altitude.safaty)) {
        document.getElementById('altitude__safaty1').placeholder = ''
        document.getElementById('altitude__safaty2').placeholder = ''
        document.getElementById('altitude__safaty3').placeholder = ''
        document.getElementById('altitude__safaty4').placeholder = ''
        document.getElementById('altitude__safaty5').placeholder = ''
      }
      else if ((String(bd.altitude.safaty).split('\n')).length === 1) {
        document.getElementById('altitude__safaty1').value = bd.altitude.safaty;
        document.getElementById('altitude__safaty2').placeholder = ''
        document.getElementById('altitude__safaty3').placeholder = ''
        document.getElementById('altitude__safaty4').placeholder = ''
        document.getElementById('altitude__safaty5').placeholder = ''
      }
      else if ((String(bd.altitude.safaty).split('\n')).length === 2) {
        document.getElementById('altitude__safaty1').value = (String(bd.altitude.safaty).split('\n'))[0]
        document.getElementById('altitude__safaty2').value = (String(bd.altitude.safaty).split('\n'))[1]
        document.getElementById('altitude__safaty3').placeholder = ''
        document.getElementById('altitude__safaty4').placeholder = ''
        document.getElementById('altitude__safaty5').placeholder = ''
      }
      else if ((String(bd.altitude.safaty).split('\n')).length === 3) {
        document.getElementById('altitude__safaty1').value = (String(bd.altitude.safaty).split('\n'))[0]
        document.getElementById('altitude__safaty2').value = (String(bd.altitude.safaty).split('\n'))[1]
        document.getElementById('altitude__safaty3').value = (String(bd.altitude.safaty).split('\n'))[2]
        document.getElementById('altitude__safaty4').placeholder = ''
        document.getElementById('altitude__safaty5').placeholder = ''
      }
      else if ((String(bd.altitude.safaty).split('\n')).length === 4) {
        document.getElementById('altitude__safaty1').value = (String(bd.altitude.safaty).split('\n'))[0]
        document.getElementById('altitude__safaty2').value = (String(bd.altitude.safaty).split('\n'))[1]
        document.getElementById('altitude__safaty3').value = (String(bd.altitude.safaty).split('\n'))[2]
        document.getElementById('altitude__safaty4').value = (String(bd.altitude.safaty).split('\n'))[3]
        document.getElementById('altitude__safaty5').placeholder = ''
      }
      else if ((String(bd.altitude.safaty).split('\n')).length === 5) {
        document.getElementById('altitude__safaty1').value = (String(bd.altitude.safaty).split('\n'))[0]
        document.getElementById('altitude__safaty2').value = (String(bd.altitude.safaty).split('\n'))[1]
        document.getElementById('altitude__safaty3').value = (String(bd.altitude.safaty).split('\n'))[2]
        document.getElementById('altitude__safaty4').value = (String(bd.altitude.safaty).split('\n'))[3]
        document.getElementById('altitude__safaty5').value = (String(bd.altitude.safaty).split('\n'))[4]
      }
      if (!(bd.altitude.fl_tansition)) {
        document.getElementById('fl_tansition1').placeholder = ''
        document.getElementById('fl_tansition2').placeholder = ''
        document.getElementById('fl_tansition3').placeholder = ''
      }
      else if ((String(bd.altitude.fl_tansition).split('\n')).length === 1) {
        document.getElementById('fl_tansition1').value = bd.altitude.fl_tansition;
        document.getElementById('fl_tansition2').placeholder = ''
        document.getElementById('fl_tansition3').placeholder = ''
      }
      else if ((String(bd.altitude.fl_tansition).split('\n')).length === 2) {
        document.getElementById('fl_tansition1').value = (String(bd.altitude.fl_tansition).split('\n'))[0]
        document.getElementById('fl_tansition2').value = (String(bd.altitude.fl_tansition).split('\n'))[1]
        document.getElementById('fl_tansition3').placeholder = ''
      }
      else if ((String(bd.altitude.fl_tansition).split('\n')).length === 3) {
        document.getElementById('fl_tansition1').value = (String(bd.altitude.fl_tansition).split('\n'))[0]
        document.getElementById('fl_tansition2').value = (String(bd.altitude.fl_tansition).split('\n'))[1]
        document.getElementById('fl_tansition3').value = (String(bd.altitude.fl_tansition).split('\n'))[2]
      }
      document.getElementById('altitude__circle').value = 
        bd.altitude.circle ? bd.altitude.circle : document.getElementById('altitude__circle').placeholder = '';
      document.getElementById('altitude__kta').value = 
        bd.altitude.kta ? bd.altitude.kta : document.getElementById('altitude__kta').placeholder = '';
      document.getElementById('deviation__kta').value = 
        bd.deviation.kta ? bd.deviation.kta : document.getElementById('deviation__kta').placeholder = '';
      document.getElementById('glidepath').value = bd.glidepath ? bd.glidepath : document.getElementById('glidepath').placeholder = '';
      document.getElementById('coordinates__kta__pz__lat').value = 
        bd.coordinates.kta.pz.lat ? bd.coordinates.kta.pz.lat : 
        document.getElementById('coordinates__kta__pz__lat').placeholder = '';
      document.getElementById('coordinates__kta__pz__lon').value = 
        bd.coordinates.kta.pz.lon ? bd.coordinates.kta.pz.lon : 
        document.getElementById('coordinates__kta__pz__lon').placeholder = '';
      document.getElementById('coordinates__kta__wgs__lat').value = 
        bd.coordinates.kta.wgs.lat ? bd.coordinates.kta.wgs.lat : 
        document.getElementById('coordinates__kta__wgs__lat').placeholder = '';
      document.getElementById('coordinates__kta__wgs__lon').value = 
        bd.coordinates.kta.wgs.lon ? bd.coordinates.kta.wgs.lon :
        document.getElementById('coordinates__kta__wgs__lon').placeholder = '';
      document.getElementById('coordinates__rsbn__pz__lat').value = 
        bd.coordinates.rsbn.pz.lat ? bd.coordinates.rsbn.pz.lat :
        document.getElementById('coordinates__rsbn__pz__lat').placeholder = '';
      document.getElementById('coordinates__rsbn__pz__lon').value = 
        bd.coordinates.rsbn.pz.lon ? bd.coordinates.rsbn.pz.lon : 
        document.getElementById('coordinates__rsbn__pz__lon').placeholder = '';
      document.getElementById('coordinates__rsbn__wgs__lat').value = 
        bd.coordinates.rsbn.wgs.lat ? bd.coordinates.rsbn.wgs.lat :
        document.getElementById('coordinates__rsbn__wgs__lat').placeholder = '';
      document.getElementById('coordinates__rsbn__wgs__lon').value = 
        bd.coordinates.rsbn.wgs.lon ? bd.coordinates.rsbn.wgs.lon : 
        document.getElementById('coordinates__rsbn__wgs__lon').placeholder = '';
      document.getElementById('coordinates__vor__wgs__lat').value = 
        bd.coordinates.vor.wgs.lat ? bd.coordinates.vor.wgs.lat :
        document.getElementById('coordinates__vor__wgs__lat').placeholder = '';
      document.getElementById('coordinates__vor__wgs__lon').value = 
        bd.coordinates.vor.wgs.lon ? bd.coordinates.vor.wgs.lon :
        document.getElementById('coordinates__vor__wgs__lon').placeholder = '';
      document.getElementById('course').value = 
        bd.course ? bd.course : document.getElementById('course').placeholder = '';
      document.getElementById('runway').value = 
        bd.runway ? bd.runway : document.getElementById('runway').placeholder = '';
      document.getElementById('altitude__transition').value = 
        bd.altitude.transition ? bd.altitude.transition : 
        document.getElementById('altitude__transition').placeholder = '';
      document.getElementById('coordinates__kta__wgs_ps__lat').value = 
        bd.coordinates.kta.wgs_ps.lat ? bd.coordinates.kta.wgs_ps.lat :
        document.getElementById('coordinates__kta__wgs_ps__lat').placeholder = '';
      document.getElementById('coordinates__kta__wgs_ps__lon').value = 
        bd.coordinates.kta.wgs_ps.lon ? bd.coordinates.kta.wgs_ps.lon :
        document.getElementById('coordinates__kta__wgs_ps__lon').placeholder = '';
      document.getElementById('coordinates__rsbn__wgs_ps__lat').value = 
        bd.coordinates.rsbn.wgs_ps.lat ? bd.coordinates.rsbn.wgs_ps.lat :
        document.getElementById('coordinates__rsbn__wgs_ps__lat').placeholder = '';
      document.getElementById('coordinates__rsbn__wgs_ps__lon').value = 
        bd.coordinates.rsbn.wgs_ps.lon ? bd.coordinates.rsbn.wgs_ps.lon :
        document.getElementById('coordinates__rsbn__wgs_ps__lon').placeholder = '';
      document.getElementById('distanse_runway').value = 
        bd.distanse_runway ? bd.distanse_runway : document.getElementById('distanse_runway').placeholder = '';
      document.getElementById('number').value = 
        bd.number ? bd.number : document.getElementById('number').placeholder = '';
      document.getElementById('number_ani').value = bd.number_ani; //
      document.getElementById('link_ani').value = 
        bd.link_ani ? bd.link_ani : document.getElementById('link_ani').placeholder = '';
      if (bd.suitability) {document.getElementById('suitability').checked  = true} else {document.getElementById('suitability').checked  = false} //
      if (bd.second_runway) {document.getElementById('second_runway').checked = true} else {document.getElementById('second_runway').checked = false} //
      document.getElementById('idAni').innerText = bd.id
    }
    else if (bd === 'New') {
      document.getElementById('city').placeholder = 'Москва';
      document.getElementById('name').placeholder = 'Москва (ЮВ)';
      document.getElementById('name_aerodroms').placeholder = 'Внуково';
      document.getElementById('callsign').placeholder = '"Внуково"';
      document.getElementById('rsbn__channel_nav').placeholder = '40';
      document.getElementById('rsbn__channel_land').placeholder = '38';
      document.getElementById('channel_vor').placeholder = '110.1';
      document.getElementById('near_beacon').placeholder = '455(ВН/СА)';
      document.getElementById('far_beacon').placeholder = '250(В/С)';
      document.getElementById('general_beacon').placeholder = '250(АС)';
      document.getElementById('channel_ils__0').placeholder = '112.3';
      document.getElementById('channel_ils__1').placeholder = '110.3';
      document.getElementById('altitude__safaty1').placeholder = '700м(ИКпод=0...70)';
      document.getElementById('altitude__safaty2').placeholder = '600м(ИКпод=70...180)';
      document.getElementById('altitude__safaty3').placeholder = '200м(ИКпод=180...200)';
      document.getElementById('altitude__safaty4').placeholder = '1400м(ИКпод=180...270)';
      document.getElementById('altitude__safaty5').placeholder = '1500м';
      document.getElementById('fl_tansition1').placeholder = '1200м(Раэр>747)';
      document.getElementById('fl_tansition2').placeholder = '1800м(Раэр=747...720)';
      document.getElementById('fl_tansition3').placeholder = '2150м';
      document.getElementById('altitude__circle').placeholder = '600';
      document.getElementById('altitude__kta').placeholder = '152';
      document.getElementById('deviation__kta').placeholder = '14';
      document.getElementById('glidepath').placeholder = '2.7';
      document.getElementById('coordinates__kta__wgs_deg__lat').placeholder = '55.600663';
      document.getElementById('coordinates__kta__wgs_deg__lon').placeholder = '37.268323';
      document.getElementById('coordinates__rsbn__pz__lat').placeholder = '55 60 06';
      document.getElementById('coordinates__rsbn__pz__lon').placeholder = '37 26 82';
      document.getElementById('coordinates__rsbn__wgs__lat').placeholder = '37° 33  ̍ 01.22';
      document.getElementById('coordinates__rsbn__wgs__lon').placeholder = '91° 23  ̍ 06.82';
      document.getElementById('coordinates__vor__pz__lat').placeholder = '55 60 06';
      document.getElementById('coordinates__vor__pz__lon').placeholder = '37 26 82';
      document.getElementById('coordinates__vor__wgs__lat').placeholder = '37° 33  ̍ 01.22';
      document.getElementById('coordinates__vor__wgs__lon').placeholder = '91° 23  ̍ 06.82';
      document.getElementById('course').placeholder = '024° 43';
      document.getElementById('runway').placeholder = '3250';
      document.getElementById('altitude__transition').placeholder = '900';
      document.getElementById('distanse_runway').placeholder = '20';
      document.getElementById('coordinates__kta__pz__lat').placeholder = '55 60 06';
      document.getElementById('coordinates__kta__pz__lon').placeholder = '37 26 82';
      document.getElementById('coordinates__kta__wgs__lat').placeholder = '37° 33 ̍ 01.22';
      document.getElementById('coordinates__kta__wgs__lon').placeholder = '91° 23 ̍ 06.82';
      document.getElementById('coordinates__kta__wgs_ps__lat').placeholder = '53 44.40';
      document.getElementById('coordinates__kta__wgs_ps__lon').placeholder = '091 23.11';
      document.getElementById('coordinates__rsbn__wgs_ps__lat').placeholder = '53 44.40';
      document.getElementById('coordinates__rsbn__wgs_ps__lon').placeholder = '091 23.11';
      document.getElementById('number').placeholder = '+79385514637(РП)';
      document.getElementById('number_ani').value = '';
      document.getElementById('link_ani').placeholder = 'https://drive.google.com/open?id=12j_P0feqAez5TZV37X78A-p_o1LQj13K';
      if (bd.suitability) {document.getElementById('suitability').checked  = false} //
      if (bd.second_runway) {document.getElementById('second_runway').checked = false} //
      document.getElementById('idAni').innerText = bdLength

    }
    } catch (e) {console.log(e)}
}

const Notes = () => {
  document.getElementById('dialog').show()
}
const Clears = () => {
  document.getElementById('city').value = '';
  document.getElementById('name').value = '';
  document.getElementById('name_aerodroms').value = '';
  document.getElementById('callsign').value = '';
  document.getElementById('rsbn__channel_nav').value = '';
  document.getElementById('rsbn__channel_land').value = '';
  document.getElementById('channel_vor').value = '';
  document.getElementById('near_beacon').value = '';
  document.getElementById('far_beacon').value = '';
  document.getElementById('general_beacon').value = '';
  document.getElementById('channel_ils__0').value = '';
  document.getElementById('channel_ils__1').value = '';
  document.getElementById('altitude__safaty1').value = '';
  document.getElementById('altitude__safaty2').value = '';
  document.getElementById('altitude__safaty3').value = '';
  document.getElementById('altitude__safaty4').value = '';
  document.getElementById('altitude__safaty5').value = '';
  document.getElementById('fl_tansition1').value = '';
  document.getElementById('fl_tansition2').value = '';
  document.getElementById('fl_tansition3').value = '';
  document.getElementById('altitude__circle').value = '';
  document.getElementById('altitude__kta').value = '';
  document.getElementById('deviation__kta').value = '';
  document.getElementById('glidepath').value = '';
  document.getElementById('coordinates__kta__wgs_deg__lat').value = '';
  document.getElementById('coordinates__kta__wgs_deg__lon').value = '';
  document.getElementById('coordinates__rsbn__pz__lat').value = '';
  document.getElementById('coordinates__rsbn__pz__lon').value = '';
  document.getElementById('coordinates__rsbn__wgs__lat').value = '';
  document.getElementById('coordinates__rsbn__wgs__lon').value = '';
  document.getElementById('coordinates__vor__pz__lat').value = '';
  document.getElementById('coordinates__vor__pz__lon').value = '';
  document.getElementById('coordinates__vor__wgs__lat').value = '';
  document.getElementById('coordinates__vor__wgs__lon').value = '';
  document.getElementById('course').value = '';
  document.getElementById('runway').value = '';
  document.getElementById('altitude__transition').value = '';
  document.getElementById('distanse_runway').value = '';
  document.getElementById('coordinates__kta__pz__lat').value = '';
  document.getElementById('coordinates__kta__pz__lon').value = '';
  document.getElementById('coordinates__kta__wgs__lat').value = '';
  document.getElementById('coordinates__kta__wgs__lon').value = '';
  document.getElementById('coordinates__kta__wgs_ps__lat').value = '';
  document.getElementById('coordinates__kta__wgs_ps__lon').value = '';
  document.getElementById('coordinates__rsbn__wgs_ps__lat').value = '';
  document.getElementById('coordinates__rsbn__wgs_ps__lon').value = '';
  document.getElementById('number').value = '';
  document.getElementById('number_ani').value = '';
  document.getElementById('link_ani').value = '';
  document.getElementById('suitability').checked  = false;
  document.getElementById('second_runway').checked = false;
  /////////////////////////////////////////////////////
  document.getElementById('city').style = 'background-color: white';
  document.getElementById('name').style = 'background-color: white';
  document.getElementById('name_aerodroms').style = 'background-color: white';
  document.getElementById('callsign').style = 'background-color: white';
  document.getElementById('rsbn__channel_nav').style = 'background-color: white';
  document.getElementById('rsbn__channel_land').style = 'background-color: white';
  document.getElementById('channel_vor').style = 'background-color: white';
  document.getElementById('near_beacon').style = 'background-color: white';
  document.getElementById('far_beacon').style = 'background-color: white';
  document.getElementById('general_beacon').style = 'background-color: white';
  document.getElementById('channel_ils__0').style = 'background-color: white';
  document.getElementById('channel_ils__1').style = 'background-color: white';
  document.getElementById('altitude__safaty1').style = 'background-color: white';
  document.getElementById('altitude__safaty2').style = 'background-color: white';
  document.getElementById('altitude__safaty3').style = 'background-color: white';
  document.getElementById('altitude__safaty4').style = 'background-color: white';
  document.getElementById('altitude__safaty5').style = 'background-color: white';
  document.getElementById('fl_tansition1').style = 'background-color: white';
  document.getElementById('fl_tansition2').style = 'background-color: white';
  document.getElementById('fl_tansition3').style = 'background-color: white';
  document.getElementById('altitude__circle').style = 'background-color: white';
  document.getElementById('altitude__kta').style = 'background-color: white';
  document.getElementById('deviation__kta').style = 'background-color: white';
  document.getElementById('glidepath').style = 'background-color: white';
  document.getElementById('coordinates__kta__wgs_deg__lat').style = 'background-color: white';
  document.getElementById('coordinates__kta__wgs_deg__lon').style = 'background-color: white';
  document.getElementById('coordinates__rsbn__pz__lat').style = 'background-color: white';
  document.getElementById('coordinates__rsbn__pz__lon').style = 'background-color: white';
  document.getElementById('coordinates__rsbn__wgs__lat').style = 'background-color: white';
  document.getElementById('coordinates__rsbn__wgs__lon').style = 'background-color: white';
  document.getElementById('coordinates__vor__pz__lat').style = 'background-color: white';
  document.getElementById('coordinates__vor__pz__lon').style = 'background-color: white';
  document.getElementById('coordinates__vor__wgs__lat').style = 'background-color: white';
  document.getElementById('coordinates__vor__wgs__lon').style = 'background-color: white';
  document.getElementById('course').style = 'background-color: white';
  document.getElementById('runway').style = 'background-color: white';
  document.getElementById('altitude__transition').style = 'background-color: white';
  document.getElementById('distanse_runway').style = 'background-color: white';
  document.getElementById('coordinates__kta__pz__lat').style = 'background-color: white';
  document.getElementById('coordinates__kta__pz__lon').style = 'background-color: white';
  document.getElementById('coordinates__kta__wgs__lat').style = 'background-color: white';
  document.getElementById('coordinates__kta__wgs__lon').style = 'background-color: white';
  document.getElementById('coordinates__kta__wgs_ps__lat').style = 'background-color: white';
  document.getElementById('coordinates__kta__wgs_ps__lon').style = 'background-color: white';
  document.getElementById('coordinates__rsbn__wgs_ps__lat').style = 'background-color: white';
  document.getElementById('coordinates__rsbn__wgs_ps__lon').style = 'background-color: white';
  document.getElementById('number').style = 'background-color: white';
  document.getElementById('link_ani').style = 'background-color: white';
}


export {OneAerodrome, Notes,Clears}