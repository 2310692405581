import { Component } from 'react';

import {OneAerodrome, Notes, Clears} from '../aerodrom/aerodrom';
import {CalcKTA, CalcRSBNPZ, CalcRSBNWGS, CalcVORPZ, CalcVORWGS } from '../calcCoord/calcCord';
import { ChanalVOR, ChanalRSBN, Name, Dprm, Bprm, Oprm, Hbez, Htr, Hkry, Ipu, LVpp} from '../valid/valid';

import '../app/app.css';

class Body extends Component {
    constructor (props) {
        super(props);
        this.state = {
            bdServ: [],
            serv: [],
            namberANI: 0,
            loading: false,
            deliteDialog: false,
            d: 0
        };
    };

    Next = async() => {
        if (this.state.namberANI === 'New') {this.setState({namberANI: 0})}
        if (this.state.namberANI + 1 < this.state.serv.length) {
            await this.setState({namberANI: this.state.namberANI + 1});
            OneAerodrome(this.state.serv[this.state.namberANI], this.state.serv.length)
        } else {
            await this.setState({namberANI: 0});
            OneAerodrome(this.state.serv[this.state.namberANI], this.state.serv.length)
        }
    }
    Back = async() => {
        if (this.state.namberANI === 'New') {this.setState({namberANI: 0})}
        if (this.state.namberANI - 1 >= 0) {
            await this.setState({namberANI: this.state.namberANI - 1});
            OneAerodrome(this.state.serv[this.state.namberANI], this.state.serv.length)
        }else {
            await this.setState({namberANI: this.state.serv.length - 1});
            OneAerodrome(this.state.serv[this.state.namberANI])
        }
    }
    New = async() => {
        await this.setState({namberANI: 'New'})
        Clears()
        OneAerodrome(this.state.namberANI, this.state.serv.length)
    }
    Counter = () => {
        if (this.state.namberANI !== 'New') {
            return `${this.state.namberANI + 1}/${this.state.serv.length}`
        } else {return `New`}
    }
    filter = async (text,namber) => {
        await this.setState({serv: this.state.bdServ})
        OneAerodrome(this.state.serv[this.state.namberANI], this.state.serv.length)
        if (text.length !== 0) {
            await this.setState({
                serv: this.state.serv.filter(i => {
                    return (i.city.toLowerCase()).indexOf(text.toLowerCase()) !== -1
                })
            })
        }
        if (document.getElementById('aniNamber').value !== '') {
            await this.setState({
                serv: this.state.serv.filter(i => {
                    return String(i.number_ani) === String(namber)
                })
            })
        }
        this.setState({namberANI: 0})
        OneAerodrome(this.state.serv[this.state.namberANI], this.state.serv.length)
    }
    dialog = () =>{
        if (!this.state.loading) {
            this.setState({loading: true})
            document.getElementById('loadd').showModal()
        } else {
            this.setState({loading: false})
            document.getElementById('loadd').close()
        }
    }
    dialogDelete = () =>{
        if (!this.state.loading) {
            this.setState({deliteDialog: true})
            document.getElementById('deleteWindow').showModal()
        } else {
            this.setState({deliteDialog: false})
            document.getElementById('deleteWindow').close()
        }
    }
    Save = async (namberANI) => {
        let newAer = {
            "city": document.getElementById('city').value,
            "name": document.getElementById('name').value,
            "name_aerodroms": document.getElementById('name_aerodroms').value,
            "callsign": document.getElementById('callsign').value,
            "coordinates": {
              "kta": {
                "wgs_ps": {
                  "lat": document.getElementById('coordinates__kta__wgs_ps__lat').value,
                  "lon": document.getElementById('coordinates__kta__wgs_ps__lon').value
                },
                "pz": {
                  "lat": document.getElementById('coordinates__kta__pz__lat').value,
                  "lon": document.getElementById('coordinates__kta__pz__lon').value
                },
                "wgs": {
                  "lat": document.getElementById('coordinates__kta__wgs__lat').value,
                  "lon": document.getElementById('coordinates__kta__wgs__lon').value
                },
                "wgs_deg": {
                  "lat": document.getElementById('coordinates__kta__wgs_deg__lat').value,
                  "lon": document.getElementById('coordinates__kta__wgs_deg__lon').value
                }
              },
              "rsbn": {
                "wgs_ps": {
                  "lat": document.getElementById('coordinates__rsbn__wgs_ps__lat').value,
                  "lon": document.getElementById('coordinates__rsbn__wgs_ps__lon').value
                },
                "pz": {
                  "lat": document.getElementById('coordinates__rsbn__pz__lat').value,
                  "lon": document.getElementById('coordinates__rsbn__pz__lon').value
                },
                "wgs": {
                  "lat": document.getElementById('coordinates__rsbn__wgs__lat').value,
                  "lon": document.getElementById('coordinates__rsbn__wgs__lon').value
                }
              },
              "vor": {
                "wgs": {
                  "lat": document.getElementById('coordinates__vor__wgs__lat').value,
                  "lon": document.getElementById('coordinates__vor__wgs__lon').value
                }
              }
            },
            "altitude": {
              "kta": document.getElementById('altitude__kta').value,
              "circle": document.getElementById('altitude__circle').value,
              "transition": document.getElementById('altitude__transition').value,
              "fl_tansition": `${document.getElementById('fl_tansition1').value}\n${document.getElementById('fl_tansition2').value}\n${document.getElementById('fl_tansition3').value}`,
              "safaty": `${document.getElementById('altitude__safaty1').value}\n${document.getElementById('altitude__safaty2').value}\n${document.getElementById('altitude__safaty3').value}\n${document.getElementById('altitude__safaty4').value}\n${document.getElementById('altitude__safaty5').value}`
    
            },
            "deviation": {
              "kta": document.getElementById('deviation__kta').value
            },
            "course": document.getElementById('course').value,
            "runway": document.getElementById('runway').value,
            "distanse_runway": document.getElementById('distanse_runway').value,
            "rsbn": {
              "channel_nav": document.getElementById('rsbn__channel_nav').value,
              "channel_land": document.getElementById('rsbn__channel_land').value
            },
            "channel_vor": document.getElementById('channel_vor').value,
            "channel_ils": [
                document.getElementById('channel_ils__0').value,
                document.getElementById('channel_ils__1').value
            ],
            "glidepath": document.getElementById('glidepath').value,
            "near_beacon": document.getElementById('near_beacon').value,
            "far_beacon": document.getElementById('far_beacon').value,
            "general_beacon": document.getElementById('general_beacon').value,
            "number": document.getElementById('number').value,
            "link_ani": document.getElementById('link_ani').value,
            "suitability": document.getElementById('suitability').checked,
            "second_runway": document.getElementById('second_runway').checked,
            "number_ani": document.getElementById('number_ani').value,
            "notes": document.getElementById('notesValue').textContent,
            "id": document.getElementById('idAni').innerText
          }
          if (namberANI === 'New') {
            this.dialog()
            let ARR = this.state.bdServ
            ARR.push(newAer)
            this.setState({bdServ: ARR})
            await this.setState({serv: this.state.bdServ})
            Clears()
            await this.setState({namberANI: this.state.serv.length - 1})
            await OneAerodrome(this.state.serv[this.state.namberANI], this.state.serv.length)
            ///Выгружаем на сервер
            fetch("https://полиграф-ш.рф:3004/newdb",  {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8'},
                body:   JSON.stringify({ 
                            newdb : ((this.state.bdServ).sort((a,b) => {
                                        if (a.city < b.city) {return -1}
                                        if (a.city > b.city) {return 1}
                                        return 0
                                    } ) ).map((e, n) => {
                                        e.id = n
                                        return e } ) ,
                            password : '5kZk8H'
                        })
            })
            .then(async (res) => {
                if (res.status === 201) {
                    fetch("https://полиграф-ш.рф:3004/", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                          },
                        body: JSON.stringify(
                            {password: '5kZk8H'}   
                            )
                    })
                    .then(response => response.json())
                    .then(aerodroms => {
                            this.setState({bdServ: aerodroms.answer, serv: aerodroms.answer})
                            return new Promise(res => res(aerodroms.answer)) })
                    //Стало
                    .then(aerodroms => OneAerodrome(aerodroms[this.state.namberANI], aerodroms.length))
                    //Было 
                    // await OneAerodrome(this.state.serv[this.state.namberANI], this.state.serv.length) 
                    this.dialog()
                }
            });
          }
          else {
            this.dialog()
            let ind = document.getElementById('idAni').innerText
            let ARR = this.state.bdServ
            ARR[ind] = newAer
            await this.setState({bdServ: ARR})
            Clears()
            await this.setState({namberANI: 0})
            this.setState({serv: this.state.bdServ})
            ///
            fetch("https://полиграф-ш.рф:3004/newdb",  {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8'},
                body:   JSON.stringify({ 
                        newdb : ((this.state.bdServ).sort((a,b) => {
                                    if (a.city < b.city) {return -1}
                                    if (a.city > b.city) {return 1}
                                    return 0
                                })).map((e, n) => {
                                    e.id = n
                                return e}),
                        password : '5kZk8H'
                        })
            })
            .then(async (res) => {
                if (res.status === 201) {
                    fetch("https://полиграф-ш.рф:3004/", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                          },
                        body: JSON.stringify(
                            {password: '5kZk8H'}   
                            )
                    })
                    .then(response => response.json())
                    .then(aerodroms => {
                        this.setState({bdServ: aerodroms.answer, serv: aerodroms.answer})
                        return new Promise(res => res(aerodroms.answer)) })
                    .then(aerodroms => OneAerodrome(aerodroms[this.state.namberANI], aerodroms.length))

                    // await OneAerodrome(this.state.serv[this.state.namberANI], this.state.serv.length) 
                    this.dialog()
                }
            });
          }
    }
    Delete = async () => {
        this.dialog()
        let ind = document.getElementById('idAni').innerText
        let ARR = this.state.bdServ
        ARR.splice(ind,1)
        await this.setState({bdServ: ARR})
            Clears()
            await this.setState({namberANI: 0})
            this.setState({serv: this.state.bdServ})
            ///
            fetch("https://полиграф-ш.рф:3004/newdb",  {
                method: 'POST',
                headers: { 'Content-Type': 'application/json;charset=utf-8'},
                body: JSON.stringify({ 
                        newdb : this.state.bdServ,
                        password : '5kZk8H'
                        })

            })
            .then(async (res) => {
                if (res.status === 201) {
                    fetch("https://полиграф-ш.рф:3004/", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                          },
                        body: JSON.stringify(
                            {password: '5kZk8H'}   
                            )
                    })
                    .then(response => response.json())
                    .then(aerodroms => {
                        this.setState({bdServ: aerodroms.answer, serv: aerodroms.answer})
                        return new Promise(res => res(aerodroms.answer)) })
                    .then(aerodroms => OneAerodrome(aerodroms[this.state.namberANI], aerodroms.length))
                    // await OneAerodrome(this.state.serv[this.state.namberANI], this.state.serv.length) 
                    this.dialog()
                }
            });
        this.dialogDelete()
    }
    Login = async () => {
        
        fetch("https://полиграф-ш.рф:3004/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
              },
            body: JSON.stringify(
                {password: document.getElementById('Password').value}   
                )
        })
        .then(response => response.json())
        .then(aerodroms => {
            if(aerodroms.answer === false) {
                document.getElementById('Password').style = 'border: 2px red solid; margin-right: 10px; height: 35px'
                return new Promise(res => res(false))
            }
            else{
                document.getElementById('Password').style = 'border: 1px black solid; margin-right: 10px; height: 35px'
                this.setState({bdServ: aerodroms.answer, serv: aerodroms.answer})
                return new Promise(res => res(aerodroms.answer))}})
        .then(aerodroms => {
            if (aerodroms) {
            OneAerodrome(aerodroms[this.state.namberANI], aerodroms.length) 
            this.setState({d: 1})}})

    }


    ////////////////////////////////////////////////////////////////////////
    


    render() {
        return this.state.bdServ.length === 0 ? 
            <dialog className='load' open>
                <div style={{fontSize: '20px', fontWeight: '900', textAlign: 'center'}}>
                    Жду пароль...
                </div>
                <div className='divLogin'>
                    <input type='text' 
                            id='Password'
                            style={{marginRight: '10px', height: '35px'}}/>
                    <button 
                        className='buttonLogin'
                        onClick={this.Login}
                        style={{height: '41px', fontWeight: '900'}}
                    >Вход</button>
                </div>
            </dialog> : 
            (
            <>
            <dialog className='deleteOk' id='deleteWindow'>
                <div style={{fontSize: '20px', fontWeight: '900'}}>
                    Удалить текущий аэродром?
                </div>
                <button className='deletess' onClick={this.Delete}>Да</button>
                <button className='deletess' onClick={() => window.deleteWindow.close()}>Отмена</button>
            </dialog>
            <dialog className='load' id='loadd'>
                <div style={{fontSize: '20px', fontWeight: '900'}}>
                    Жди! Данные загружаются...
                </div>
            </dialog>
            <table>
            <caption> <h1>Проверь данные, при необходимости нажми сохранить (после таблицы)</h1>
                <h2>
                <button className='buttonNext'
                    onClick={this.Back}
                >←</button>
                <button className='buttonNext'>{this.Counter()}</button>
                <button 
                    className='buttonNext'
                    onClick={this.Next}
                >→</button>
                </h2>
                <h3>Фильтры:</h3>
                <h4>
                по городу - 
                <input type='text' id='SityName' 
                    onChange={(e) => this.filter(e.target.value, document.getElementById('aniNamber').value)}
                    />
                </h4>
                <h4> 
                <select id='aniNamber' onChange={() => this.filter(document.getElementById('SityName').value, document.getElementById('aniNamber').value)}
                >
                    <option value="">По № сборника</option>
                    <option value="11">11</option>
                    <option value="12">12</option>
                    <option value="13">13</option>
                    <option value="14">21</option>
                    <option value="15">21</option>
                    <option value="21">21</option>
                    <option value="23-1">23-1</option>
                    <option value="23-2">23-2</option>
                    <option value="24">24</option>
                    <option value="25-1">25-1</option>
                    <option value="25-2">25-2</option>
                </select>
                <button className='buttonNew' onClick={this.New}>Добавить новый</button>
                </h4>
                
            </caption>
            <tbody>
                    <tr>
                        <th className='variable'
                            colSpan="2"
                            >Информация со сборника
                        </th>
                    </tr>
                    <tr>
                        <th colSpan="2" className='variable'>
                            <button 
                                className='notes'
                                id='notes'
                                onClick={Notes}
                            >Примечания</button>
                            <dialog className='dialog' id='dialog'>
                                <div contentEditable="true" id='notesValue'>
                                </div>
                                <button 
                                    className='clicDialog'
                                    onClick={() => window.dialog.close()}
                                >ОК</button>
                            </dialog> 
                        </th>
                    </tr>  
                    <tr>
                        <th className='variable'>Город</th>
                        <th className='variable'><input 
                            type="text" 
                            placeholder='Москва' 
                            id='city'
                            onChange={(e) => Name(e)}
                            />
                        </th>
                    </tr>
                    <tr>
                        <th className='variable'>Если 2 полосы</th>
                        <th className='variable'><input 
                            type="text" 
                            placeholder='Москва (ЮВ)' 
                            id='name'
                            onChange={(e) => Name(e)}
                            />
                        </th>
                    </tr>
                    <tr>
                        <th className='variable'>Аэродром</th>
                        <td className='variable'><input 
                            type="text" 
                            placeholder='Внуково'
                            id='name_aerodroms'
                            onChange={(e) => Name(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'>Позывной</th>
                        <td className='variable'><input 
                            type="text" 
                            placeholder='"Внуково"'
                            id='callsign'
                            onChange={(e) => Name(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'>РСБН</th>
                        <td className='variable'><input 
                            type="number" 
                            placeholder='40'
                            id='rsbn__channel_nav'
                            onChange={(e) => ChanalRSBN(e)}
                    /></td>
                    </tr>
                    <tr>
                        <th className='variable'>РМС</th>
                        <td className='variable'><input 
                            type="number" 
                            placeholder='38'
                            id='rsbn__channel_land'
                            onChange={(e) => ChanalRSBN(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'>Канал VOR</th>
                        <td className='variable'><input 
                            type="text"  
                            placeholder='110.1'
                            id='channel_vor'
                            onChange={(e) => ChanalVOR(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'>ДПРМ</th>
                        <td className='variable'><input 
                            type="text" 
                            placeholder='455(ВН/СА)'
                            id='near_beacon'
                            onChange={(e) => Dprm(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'>БПРМ</th>
                        <td className='variable'><input 
                            type="text" 
                            placeholder='250(В/С)'
                            id='far_beacon'
                            onChange={(e) => Bprm(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'>ОПРМ</th>
                        <td className='variable'><input 
                            type="text" 
                            placeholder='250(АС)'
                            id='general_beacon'
                            onChange={(e) => Oprm(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'>ILS основной старт</th>
                        <td className='variable'><input 
                            type="text" 
                            placeholder='112.3'
                            id='channel_ils__0'
                            onChange={(e) => ChanalVOR(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'>ILS обратный старт</th>
                        <td className='variable'><input 
                            type="text" 
                            placeholder='110.3'
                            id='channel_ils__1'
                            onChange={(e) => ChanalVOR(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th 
                            className='variable' colSpan="2">Н безопасная</th>
                    </tr>
                    <tr>
                    <td className='variable'
                        colSpan="2">
                            <input 
                            type="text"   
                            placeholder='700м(ИКпод=0...70)'
                            id='altitude__safaty1'
                            style={{width: '320px'}}
                            onChange={(e) => Hbez(e)}
                        /></td>
                    </tr>
                    <tr>
                    <td className='variable'
                        colSpan="2">
                            <input 
                            type="text"   
                            placeholder='600м(ИКпод=70...180)'
                            id='altitude__safaty2'
                            style={{width: '320px'}}
                            onChange={(e) => Hbez(e)}
                        /></td>
                    </tr>
                    <tr>
                    <td className='variable'
                        colSpan="2">
                            <input 
                            type="text"   
                            placeholder='200м(ИКпод=180...200)'
                            id='altitude__safaty3'
                            style={{width: '320px'}}
                            onChange={(e) => Hbez(e)}
                        /></td>
                    </tr>
                    <tr>
                    <td className='variable'
                        colSpan="2">
                            <input 
                            type="text"   
                            placeholder='1400м(ИКпод=180...270)'
                            id='altitude__safaty4'
                            style={{width: '320px'}}
                            onChange={(e) => Hbez(e)}
                        /></td>
                    </tr>
                    <tr>
                        <td className='variable'
                            colSpan="2">
                            <input 
                            type="text"   
                            placeholder='1500м'
                            id='altitude__safaty5'
                            style={{width: '320px'}}
                            onChange={(e) => Hbez(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable' colSpan="2">Н эшелона перехода</th>
                    </tr>
                    <tr>
                        <td className='variable'
                            colSpan="2">
                            <input 
                                type="text"   
                                placeholder='1200м(Раэр>747)'
                                id='fl_tansition1'
                                style={{width: '320px'}}
                                onChange={(e) => Htr(e)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <td className='variable'
                            colSpan="2">
                            <input 
                                type="text"   
                                placeholder='1800м(Раэр=747...720)'
                                id='fl_tansition2'
                                style={{width: '320px'}}
                                onChange={(e) => Htr(e)}
                        />
                        </td>
                    </tr>
                    <tr>
                        <td className='variable'
                            colSpan="2">
                                <input 
                            type="text"   
                            placeholder='2150м'
                            id='fl_tansition3'
                            style={{width: '320px'}}
                            onChange={(e) => Htr(e)}
                        />
                        </td>
                    </tr>
                    <tr>
                        <th className='variable'>Н круга</th>
                        <td className='variable'><input 
                            type="number" 
                            placeholder='600'
                            id='altitude__circle'
                            onChange={(e) => Hkry(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'>Н кта</th>
                        <td className='variable'><input 
                            type="text" 
                            placeholder='152'
                            id='altitude__kta'
                            onChange={(e) => Name(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'>ΔМ</th>
                        <td className='variable'><input 
                            type="text" 
                            placeholder='14'
                            id='deviation__kta'
                            onChange={(e) => Name(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'>Угол накл. глиссады</th>
                        <td className='variable'><input 
                            type="text" 
                            placeholder='2.7'
                            id='glidepath'
                            onChange={(e) => Name(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='attention'
                            colSpan="2"
                            >Информация с интернета (координаты центра ВПП)
                        </th>
                    </tr>
                    <tr>
                        <th className='attention'
                            colSpan="2"
                            style={{color: 'red'}}
                            >Самый точный способ
                        </th>
                    </tr>
                    <tr>
                        <th className='attention'
                            style={{color: 'red'}}
                            >Широта КТА с Яндекс
                        </th>
                        <th className='attention'><input 
                            type="text" 
                            placeholder='55.600663'
                            id='coordinates__kta__wgs_deg__lat'
                            onChange={(e) => CalcKTA(e)}
                            />
                        </th>
                    </tr>
                    <tr>
                        <th className='attention'
                            style={{color: 'red'}}
                            >Долгота КТА с Яндекс
                        </th>
                        <td className='attention'><input 
                            type="text" 
                            placeholder='37.268323'
                            id='coordinates__kta__wgs_deg__lon'
                            onChange={(e) => CalcKTA(e)}
                            />
                        </td>
                    </tr>
                    <tr>
                        <th className='attention'
                            colSpan="2"
                            >Заполни то, что есть в сборнике
                        </th>
                    </tr>
                    <tr>
                        <th className='attention'>РСБН широта ПЗ-90</th>
                        <td className='attention'><input 
                            type="text" 
                            placeholder='55 60 06'
                            id='coordinates__rsbn__pz__lat'
                            onChange={(e) => CalcRSBNPZ(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='attention'>РСБН долгота ПЗ-90</th>
                        <td className='attention'><input 
                            type="text" 
                            placeholder='37 26 82'
                            id='coordinates__rsbn__pz__lon'
                            onChange={(e) => CalcRSBNPZ(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='attention'>РСБН широта WGS </th>
                        <td className='attention'><input 
                            type="text" 
                            placeholder='37° 33  ̍ 01.22'
                            id='coordinates__rsbn__wgs__lat'
                            onChange={(e) => CalcRSBNWGS(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='attention'>РСБН долгота WGS</th>
                        <td className='attention'><input 
                            type="text" 
                            placeholder='91° 23  ̍ 06.82'
                            id='coordinates__rsbn__wgs__lon'
                            onChange={(e) => CalcRSBNWGS(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='attention'>VOR широта ПЗ-90</th>
                        <td className='attention'><input 
                            type="text" 
                            placeholder='55 60 06'
                            id='coordinates__vor__pz__lat'
                            onChange={(e) => CalcVORPZ(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='attention'>VOR долгота ПЗ-90</th>
                        <td className='attention'><input 
                            type="text"    
                            placeholder='37 26 82'
                            id='coordinates__vor__pz__lon'
                            onChange={(e) => CalcVORPZ(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='attention'>VOR широта WGS</th>
                        <td className='attention'><input 
                            type="text" 
                            placeholder='37° 33  ̍ 01.22'
                            id='coordinates__vor__wgs__lat'
                            onChange={(e) => CalcVORWGS(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='attention'>VOR долгота WGS</th>
                        <td className='attention'><input 
                            type="text" 
                            placeholder='91° 23  ̍ 06.82'
                            id='coordinates__vor__wgs__lon'
                            onChange={(e) => CalcVORWGS(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'
                            colSpan="2"
                            >Информация со сборника
                        </th>
                    </tr>
                    <tr>
                        <th className='variable'>ИПУ</th>
                        <td className='variable'><input 
                            type="text" 
                            placeholder='124° 43'
                            id='course'
                            onChange={(e) => Ipu(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'>L впп</th>
                        <td className='variable'><input 
                            type="number" 
                            placeholder='3250'
                            id='runway'
                            onChange={(e) => LVpp(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'>Н перехода</th>
                        <td className='variable'><input
                            type="text" 
                            placeholder='2100'
                            id='altitude__transition'
                            onChange={(e) => LVpp(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'>Дальность выхода ВПП</th>
                        <td className='variable'><input 
                            type="number" 
                            placeholder='20'
                            id='distanse_runway'
                            onChange={(e) => ChanalRSBN(e)}
                        /></td>
                    </tr>
                    <tr>
                        <th className='calculated'
                            colSpan="2"
                            >Расчётные значения
                        </th>
                    </tr>
                    <tr>
                        <th className='calculated'>ПЗ-90 широта</th>
                        <td className='calculated'><input 
                            type="text" 
                            placeholder='55 60 06' 
                            id='coordinates__kta__pz__lat'
                        /></td>
                    </tr>
                    <tr>
                        <th className='calculated'>ПЗ-90 долгота</th>
                        <td className='calculated'><input 
                            type="text" 
                            placeholder='37 26 82'
                            id='coordinates__kta__pz__lon'
                        /></td>
                    </tr>
                    <tr>
                        <th className='calculated'>WGS широта</th>
                        <td className='calculated'><input 
                            type="text" 
                            placeholder='37° 33 ̍ 01.22'
                            id='coordinates__kta__wgs__lat'
                        /></td>
                    </tr>
                    <tr>
                        <th className='calculated'>WGS долгота</th>
                        <td className='calculated'><input 
                            type="text" 
                            placeholder='91° 23 ̍ 06.82'
                            id='coordinates__kta__wgs__lon'
                        /></td>
                    </tr>
                    <tr>
                        <th className='calculated'>ПС-5 широта</th>
                        <td className='calculated'><input 
                            type="text" 
                            placeholder='53 44.40'
                            id='coordinates__kta__wgs_ps__lat'
                        /></td>
                    </tr>
                    <tr>
                        <th className='calculated'>ПС-5 долгота</th>
                        <td className='calculated'><input 
                            type="text" 
                            placeholder='091 23.11'
                            id='coordinates__kta__wgs_ps__lon'
                        /></td>
                    </tr>
                    <tr>
                        <th className='calculated'>ПС-5 РСБН широта</th>
                        <td className='calculated'><input 
                            type="text" 
                            placeholder='53 44.40'
                            id='coordinates__rsbn__wgs_ps__lat'
                        /></td>
                    </tr>
                    <tr>
                        <th className='calculated'>ПС-5 РСБН долгота</th>
                        <td className='calculated'><input 
                            type="text" 
                            placeholder='091 23.11'
                            id='coordinates__rsbn__wgs_ps__lon'
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'
                            colSpan="2"
                            >Прочие значения
                        </th>
                    </tr>
                    <tr>
                        <th className='variable'>Телефон</th>
                        <td className='variable'><input 
                            type="text" 
                            placeholder='+79385514637(РП)'
                            id='number'
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'>№ сборника</th>
                        <td className='variable'>
                        <select id='number_ani'>
                            <option value="">По № сборника</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">21</option>
                            <option value="15">21</option>
                            <option value="21">21</option>
                            <option value="23-1">23-1</option>
                            <option value="23-2">23-2</option>
                            <option value="24">24</option>
                            <option value="25-1">25-1</option>
                            <option value="25-2">25-2</option>
                        </select>
                        </td>
                    </tr>
                    <tr>
                        <th className='variable'>Ссылка на сборник</th>
                        <td className='variable'><input 
                            type="text" 
                            placeholder='https://drive.google.com/open?id=12j_P0feqAez5TZV37X78A-p_o1LQj13K'
                            id='link_ani'
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'>Подходит для нас</th>
                        <td className='variable'><input 
                            type="checkbox" 
                            id='suitability'
                        /></td>
                    </tr>
                    <tr>
                        <th className='variable'>Несколько ВПП</th>
                        <td className='variable'><input 
                            type="checkbox" 
                            id='second_runway'
                        /></td>
                    </tr>              
                </tbody>
                <tfoot className='variable'>
                    <th colSpan="2">
                        <h1>
                            <button 
                                className='buttonSave' 
                                onClick={() => this.Save(this.state.namberANI)}
                                >Сохранить изменения
                            </button>
                        </h1>
                        <h1>
                            <button id='idAni' style={{ width: "60px", height: "40px", margin: "3px", fontSize: "15px",fontWeight: "900", display: "none"}}></button>
                            <button className='delite' id='delAer' onClick={this.dialogDelete}>Удалить из базы</button>
                        </h1>
                    </th>
                </tfoot>   
        </table>
        </>
        )
    }
}

export default Body;