import { CordYa, CordPr, CordWgs } from '../valid/valid'


function degrees3 (deg) {
    if (String(deg).length === 3) {return deg}
    if (String(deg).length === 2) {return `0${deg}`}
    if (String(deg).length === 1) {return `00${deg}`}
}
function degrees2 (deg) {
    if (String(deg).length === 2) {return deg}
    if (String(deg).length === 1) {return `0${deg}`}
}
function degrees5 (deg) {
    if (String(deg).length === 5) {return deg}
    if (String(deg).length === 4) {return `0${deg}`}
}

const CalcKTA = (e) => {
    CordYa(e) // валидация
    //КТА
    if ((document.getElementById('coordinates__kta__wgs_deg__lat').value) && 
        (document.getElementById('coordinates__kta__wgs_deg__lon').value)) {  //Если есть координаты КТА с интернета
            //КТА WGS ; WGS ПС-5
            const latInt = document.getElementById('coordinates__kta__wgs_deg__lat').value //55.600663
            const lonInt = document.getElementById('coordinates__kta__wgs_deg__lon').value //37.268323

            const degreesLat = degrees2(Math.trunc(latInt)); // получили градусы
            const degreesLon = degrees3(Math.trunc(lonInt));

            const ostPsLat = degrees5(((latInt - Math.trunc(latInt))*60).toFixed(2)); // минуты и сотые минут для ПС-5
            const ostPsLon = degrees5(((lonInt - Math.trunc(lonInt))*60).toFixed(2));

            const latPS = `${degreesLat} ${ostPsLat}`; //готовое значение для ПС-5
            const lonPS = `${degreesLon} ${ostPsLon}`;
            
            const minMLat = degrees2(Math.trunc(ostPsLat));   //минуты для малахит
            const minMLon = degrees2(Math.trunc(ostPsLon));

            const secMLat = degrees5(((((latInt - Math.trunc(latInt))*60) - minMLat)*60).toFixed(2)); //секунды для малахит
            const secMLon = degrees5(((((lonInt - Math.trunc(lonInt))*60) - minMLon)*60).toFixed(2));

            const secPzLat = degrees2(Math.trunc((((latInt - Math.trunc(latInt))*60) - minMLat)*60)); //секунды для ПЗ
            const secPZLon = degrees2(Math.trunc((((lonInt - Math.trunc(lonInt))*60) - minMLon)*60));

            const latM = `${degreesLat}° ${minMLat}  ̍ ${secMLat}''`; // готовое для малахита
            const lonM = `${degreesLon}° ${minMLon}  ̍ ${secMLon}''`;

            const latPz = `${degreesLat} ${minMLat} ${secPzLat}`; // готовое для ПЗ
            const lonPz = `${degreesLon} ${minMLon} ${secPZLon}`;

            document.getElementById('coordinates__kta__wgs__lat').value = latM;
            document.getElementById('coordinates__kta__wgs__lon').value = lonM;
            document.getElementById('coordinates__kta__wgs_ps__lat').value = latPS;
            document.getElementById('coordinates__kta__wgs_ps__lon').value = lonPS;
            document.getElementById('coordinates__kta__pz__lat').value = latPz;
            document.getElementById('coordinates__kta__pz__lon').value = lonPz;
        }
}

const CalcRSBNPZ = (e) => {
    CordPr(e)
    //РСБН

    if ((document.getElementById('coordinates__rsbn__pz__lat').value) && 
        (document.getElementById('coordinates__rsbn__pz__lon').value)) {  //Если есть координаты РСБН ПЗ-90
        //РСБН WGS ; WGS ПС-5
        const latInt = (document.getElementById('coordinates__rsbn__pz__lat').value).split(' '); //50 60 06
        const lonInt = (document.getElementById('coordinates__rsbn__pz__lon').value).split(' ') //37 26 82
            
        const degreesLat = degrees2(latInt[0]); // получили градусы
        const degreesLon = degrees3(lonInt[0]);

        const minLat = degrees2(latInt[1]); // минуты
        const minLon = degrees2(lonInt[1]);

        const secLat = degrees2(latInt[2]); // секунды
        const secLon = degrees2(lonInt[2]);

        const minPSLat = degrees5((+latInt[1] + (latInt[2] / 60)).toFixed(2))
        const minPSLon = degrees5((+lonInt[1] + (lonInt[2] / 60)).toFixed(2))

        const latM = `${degreesLat}° ${minLat}  ̍ ${secLat}.00''`; // готовое для малахита
        const lonM = `${degreesLon}° ${minLon}  ̍ ${secLon}.00''`;

        const latPS = `${degreesLat} ${minPSLat}`; //готовое значение для ПС-5
        const lonPS = `${degreesLon} ${minPSLon}`;
        
        document.getElementById('coordinates__rsbn__wgs__lat').value = latM;
        document.getElementById('coordinates__rsbn__wgs__lon').value = lonM;
        document.getElementById('coordinates__rsbn__wgs_ps__lat').value = latPS;
        document.getElementById('coordinates__rsbn__wgs_ps__lon').value = lonPS;
    }
}

const CalcRSBNWGS = (e) => {
    CordWgs(e) //валидация

    if ((document.getElementById('coordinates__rsbn__wgs__lat').value) && 
        (document.getElementById('coordinates__rsbn__wgs__lon').value)) {  //Если есть координаты РСБН WGS
            //РСБН ПЗ 90 ; WGS ПС-5
        const latInt = (document.getElementById('coordinates__rsbn__wgs__lat').value)
            .replace(/\D/g,'');//55° 33  ̍ 16.20''
        const lonInt = (document.getElementById('coordinates__rsbn__wgs__lon').value)
            .replace(/\D/g,'');//037° 09  ̍ 18.54''

        const secLat = +latInt.slice(-4,-2) + ((+latInt.slice(-2) >= 50) ? 1 : 0); // секунды
        const secLon = +lonInt.slice(-4,-2) + ((+lonInt.slice(-2) >= 50) ? 1 : 0);

        const minLat = latInt.slice(-6,-4); //минуты
        const minLon = lonInt.slice(-6,-4);


        const degreesLat = +latInt.slice(0,-6); // получили градусы
        const degreesLon = +lonInt.slice(0,-6);

        const minPSLat = (+latInt.slice(-6,-4) + (secLat / 60)).toFixed(2); //минуты PS-5
        const minPSLon = (+lonInt.slice(-6,-4) + (secLon / 60)).toFixed(2);

        const latPz = `${degrees2(degreesLat)} ${degrees2(minLat)} ${degrees2(secLat)}`; // готовое для ПЗ-90
        const lonPz = `${degrees3(degreesLon)} ${degrees2(minLon)} ${degrees2(secLon)}`;

        const latPS = `${degrees2(degreesLat)} ${degrees5(minPSLat)}`; //готовое значение для ПС-5
        const lonPS = `${degrees3(degreesLon)} ${degrees5(minPSLon)}`;
        
        document.getElementById('coordinates__rsbn__pz__lat').value = latPz;
        document.getElementById('coordinates__rsbn__pz__lon').value = lonPz;
        document.getElementById('coordinates__rsbn__wgs_ps__lat').value = latPS;
        document.getElementById('coordinates__rsbn__wgs_ps__lon').value = lonPS;
    }
}

const CalcVORPZ = (e) => {
        CordPr(e) //валидация
    //VOR

    if ((document.getElementById('coordinates__vor__pz__lat').value) && 
        (document.getElementById('coordinates__vor__pz__lon').value)) {  //Если есть координаты VOR ПЗ-90
        
        const latInt = (document.getElementById('coordinates__vor__pz__lat').value).split(' '); //50 60 06
        const lonInt = (document.getElementById('coordinates__vor__pz__lon').value).split(' ') //37 26 82
            
        const degreesLat = degrees2(latInt[0]); // получили градусы
        const degreesLon = degrees3(lonInt[0]);

        const minLat = degrees2(latInt[1]); // минуты
        const minLon = degrees2(lonInt[1]);

        const secLat = degrees2(latInt[2]); // секунды
        const secLon = degrees2(lonInt[2]);

        // const minPSLat = degrees5((+latInt[1] + (latInt[2] / 60)).toFixed(2)) //минуты ПС-5
        // const minPSLon = degrees5((+lonInt[1] + (lonInt[2] / 60)).toFixed(2))

        const latM = `${degreesLat}° ${minLat}  ̍ ${secLat}.00''`; // готовое для малахита
        const lonM = `${degreesLon}° ${minLon}  ̍ ${secLon}.00''`;

        // const latPS = `${degreesLat} ${minPSLat}`; //готовое значение для ПС-5
        // const lonPS = `${degreesLon} ${minPSLon}`;
        
        document.getElementById('coordinates__vor__wgs__lat').value = latM;
        document.getElementById('coordinates__vor__wgs__lon').value = lonM;
    }

    
}

const CalcVORWGS = (e) => { 
    CordWgs(e) //валидация

    if ((document.getElementById('coordinates__vor__wgs__lat').value) && 
        (document.getElementById('coordinates__vor__wgs__lon').value)) {  //Если есть координаты VOR WGS
    const latInt = (document.getElementById('coordinates__vor__wgs__lat').value)
        .replace(/\D/g,'');//55° 33  ̍ 16.20''
    const lonInt = (document.getElementById('coordinates__vor__wgs__lon').value)
        .replace(/\D/g,'');//037° 09  ̍ 18.54''

    const secLat = +latInt.slice(-4,-2) + ((+latInt.slice(-2) >= 50) ? 1 : 0); // секунды
    const secLon = +lonInt.slice(-4,-2) + ((+lonInt.slice(-2) >= 50) ? 1 : 0);

    const minLat = latInt.slice(-6,-4); //минуты
    const minLon = lonInt.slice(-6,-4);


    const degreesLat = +latInt.slice(0,-6); // получили градусы
    const degreesLon = +lonInt.slice(0,-6);

    // const minPSLat = (+latInt.slice(-6,-4) + (secLat / 60)).toFixed(2); //минуты PS-5
    // const minPSLon = (+lonInt.slice(-6,-4) + (secLon / 60)).toFixed(2);

    const latPz = `${degrees2(degreesLat)} ${degrees2(minLat)} ${degrees2(secLat)}`; // готовое для ПЗ-90
    const lonPz = `${degrees3(degreesLon)} ${degrees2(minLon)} ${degrees2(secLon)}`;

    // const latPS = `${degrees2(degreesLat)} ${degrees5(minPSLat)}`; //готовое значение для ПС-5
    // const lonPS = `${degrees3(degreesLon)} ${degrees5(minPSLon)}`;
    
    document.getElementById('coordinates__vor__pz__lat').value = latPz;
    document.getElementById('coordinates__vor__pz__lon').value = lonPz;
    }
}


export {CalcKTA, CalcRSBNPZ, CalcRSBNWGS, CalcVORPZ, CalcVORWGS};